/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dropzone from 'react-dropzone';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layer, Stage, Transformer } from 'react-konva';
import Konva from 'konva';
import {
  Backdrop,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Popper,
  Stack,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { ContentCopy } from '@mui/icons-material';
import { Provider, ReactReduxContext } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { useHotkeys } from 'react-hotkeys-hook';
import { serialize } from 'object-to-formdata';
import useTransformer from '../hook/useTransformer';
import { StageData } from '../redux/currentStageData';
import ImageItem, { ImageItemProps } from '../components/konvaItem/ImageItem';
import useSelection from '../hook/useSelection';
import useItem from '../hook/useItem';
import Panel from '../components/panel/Panel';
import { Preview, PreviewRef } from '../components/preview/Preview';
import { useAuth } from '../hook/use-auth';
import WelcomeModal from '../components/modals/WelcomeModal';
import LoginModal from '../components/modals/LoginModal';
import TextItem, { TextItemProps } from '../components/konvaItem/TextItem';
import { canvasHeight, canvasWidth } from '../components/CanvasConstants';
import AssetViewerModal from '../components/modals/AssetViewerModal';
import useWorkHistory, { StageSnapshot } from '../hook/useWorkHistory';
import useHotkeyFunc from '../hook/useHotKeyFunc';
import Drop from '../util/Drop';
import useToken from '../hook/useToken';
import { TokenState } from '../redux/token';
import AccountModal from '../components/modals/AccountModal';
import {
  useFavoriteRealmToken,
  useGetUserConfig,
  useUnFavoriteRealmToken,
  useUpdateRealmTokenFormData,
} from '../generated/realmWorldsApiComponents';
import Notification from '../components/notifications/Notification';
import useNotification from '../hook/useNotification';
import EllipsisMenu from '../components/menus/EllipsisMenu';
import { Note } from '../generated/realmWorldsApiSchemas';

const drawerWidth = 65;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#5D5D5D',
      main: '#FF6746',
      dark: '#262626',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    background: {
      paper: '#3D3D3D',
      default: '#3D3D3D',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b',
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #2b2b2b',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#FF6746',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          backgroundColor: '#181818',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 47,
          minHeight: 47,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& button': {
            borderRadius: '10px 10px 0px 0px',
            border: '2px solid #5D5D5D',
          },
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {},
          '&.Mui-selected': {
            backgroundColor: '#5D5D5D',
            color: '#fff',
            '&:hover': {},
          },
        },
      },
    },
    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       backgroundColor: "#3D3D3D",
    //     }
    //   }
    // }
  },
});

function DashboardContent() {
  const [recentChangeName, setRecentChangeName] = useState<string>('Inital');
  const [past, setPast] = useState<StageSnapshot[]>([]);
  const [future, setFuture] = useState<StageSnapshot[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { goToFuture, goToPast, recordPast, clearHistory, current } =
    useWorkHistory(past, future, setPast, setFuture);

  const { deleteItems } = useHotkeyFunc();

  const { data: userConfig, isLoading: userConfigIsLoading } = useGetUserConfig(
    {},
    {
      retry: false,
    },
  );

  const { token, setToken, clearToken, updateToken } = useToken();

  const [openWelcomeModal, setOpenWelcomeModal] = React.useState(true);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const [openAssetViewerModal, setOpenAssetViewerModal] = React.useState(false);
  const [openAccountViewer, setOpenAccountViewer] = React.useState(false);

  const [container, setContainer] = useState<HTMLDivElement>();

  const transformer = useTransformer();
  const { selectedItems, onSelectItem, setSelectedItems } =
    useSelection(transformer);
  const { stageData, createItem, removeItem, clearItems } = useItem();

  const stageRef = useRef() as MutableRefObject<Konva.Stage>;
  const [open] = useState(false);

  // AUTH
  const auth = useAuth();
  const handleLogout = () => {
    auth?.signout();
  };

  const handleCopyAccessToken = async () => {
    navigator.clipboard.writeText((await auth?.user?.getIdToken()) || '');
  };

  const [anchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenu = () => {
    setOpenLoginModal(true);
  };

  // History
  const [historyMenuOpen, setHistoryMenuOpen] = React.useState(false);
  const historyMenuRef = React.useRef<HTMLButtonElement>(null);

  const handleHistoryToggle = () => {
    setHistoryMenuOpen((prevOpen) => !prevOpen);
  };

  const handleHistoryMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      historyMenuRef.current &&
      historyMenuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setHistoryMenuOpen(false);
  };

  const setStateSizeToFitIn = useCallback(() => {
    if (!stageRef.current || !stageRef.current.container().parentElement) {
      return;
    }
    const containerWidth =
      stageRef.current.container().parentElement!.offsetWidth;

    const scale = containerWidth / canvasWidth;

    stageRef.current.width(canvasWidth * scale);
    stageRef.current.height(canvasHeight * scale);
    stageRef.current.scale({ x: scale, y: scale });

    stageRef.current.batchDraw();
  }, [stageRef]);

  useEffect(() => {
    window.addEventListener('load', setStateSizeToFitIn);
    window.addEventListener('resize', setStateSizeToFitIn);
  }, [setStateSizeToFitIn]);

  useEffect(() => {
    if (!stageRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setStateSizeToFitIn();
      });
    });
    resizeObserver.observe(stageRef.current.container());
    return () => resizeObserver.disconnect(); // clean up
  }, [setStateSizeToFitIn]);

  const onSelectEmptyBackground = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      e.target.getType() === 'Stage' && onSelectItem(e);
    },
    [onSelectItem],
  );

  const onMouseDownOnStage = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      onSelectEmptyBackground(e);
    },
    [onSelectEmptyBackground],
  );

  const renderObject = (item: StageData) => {
    switch (item.attrs.dataItemType) {
      case 'image':
        return (
          <ImageItem
            key={`image-${item.id}`}
            data={item as ImageItemProps['data']}
            onDragEnd={() => setRecentChangeName('Image Dragged')}
            onSelect={onSelectItem}
          />
        );
      case 'text':
        return (
          <TextItem
            key={`image-${item.id}`}
            data={item as TextItemProps['data']}
            transformer={transformer}
            onDragEnd={() => setRecentChangeName('Text Dragged')}
            onSelect={onSelectItem}
          />
        );
      default:
        return null;
    }
  };

  const handleNewChange = (changeName: string) => {
    setRecentChangeName(changeName);
  };

  const [imageDropped, setImageDropped] = React.useState(false);
  const handleImageDrop = (images: any[]) => {
    const imageSrc = new Image();
    const image = images[0];
    Object.assign(image, {
      preview: URL.createObjectURL(image),
    });

    removeItem('image');
    imageSrc.onload = () => {
      const width = imageSrc.naturalWidth;
      const height = imageSrc.naturalHeight;
      const newImage: StageData = {
        id: 'image',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - width) / 2,
          y: (canvasHeight - height) / 2,
          width,
          height,
          src: image.preview,
          zIndex: 5,
          draggable: true,
          brightness: 0,
          filters: null,
          updatedAt: Date.now(),
        },
      };

      createItem(newImage);
      handleNewChange('Updated Image');
    };

    imageSrc.src = image.preview;
    setImageDropped(true);

    const defaultTokenName = `untitled_${new Date().toLocaleString('default', {
      month: 'short',
    })}.${new Date().getDate()}`;
    const newToken: TokenState = {
      data: {
        id: null,
        name: defaultTokenName,
        droppedImageBlob: images[0],
        droppedImageSrc: image.preview,
        edited: true,
      },
    };
    setToken(newToken);
  };

  useEffect(() => {
    const stageSnapshot: StageSnapshot = {
      name: recentChangeName,
      stageData,
    };
    const image = stageData.find(
      (data) => data.id === 'image' || data.attrs.id === 'image',
    );
    if (image) {
      setImageDropped(true);
    } else {
      setImageDropped(false);
    }

    let edited = false;
    if (stageData.length !== 0 && token?.data !== null) {
      if (JSON.stringify(stageData) !== JSON.stringify(token.data.edits)) {
        edited = true;
      }
    }

    updateToken(() => ({
      edited,
    }));

    recordPast(stageSnapshot);
  }, [stageData]);

  const handleCanvasImageDrop = (files: FileList) => {
    handleImageDrop([files[0]]);
  };

  const sortedStageData = useMemo(
    () =>
      stageData.sort((a, b) => {
        if (a.attrs.zIndex === b.attrs.zIndex) {
          if (a.attrs.zIndex < 0) {
            return b.attrs.updatedAt - a.attrs.updatedAt;
          }
          return a.attrs.updatedAt - b.attrs.updatedAt;
        }
        return a.attrs.zIndex - b.attrs.zIndex;
      }),
    [stageData],
  );

  const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
  const mainMenuRef = React.useRef<HTMLButtonElement>(null);

  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const accountMenuRef = React.useRef<HTMLButtonElement>(null);

  function handleOpenAssetViewer() {
    setOpenAssetViewerModal(true);
  }

  function handleOpenAccountViewer() {
    setOpenAccountViewer(true);
  }

  const handleToggle = () => {
    setMainMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      mainMenuRef.current &&
      mainMenuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMainMenuOpen(false);
  };

  const handleAccountMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      accountMenuRef.current &&
      accountMenuRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setAccountMenuOpen(false);
  };

  const handleExitSession = ({ wait }: { wait: boolean }) => {
    if (wait) {
      setLoading(true);
      setTimeout(() => {
        clearItems();
        clearHistory();
        clearToken();
        setImageDropped(false);
        setMainMenuOpen(false);
        setLoading(false);
        setOpenAssetViewerModal(true);
      }, 3000);
    } else {
      clearItems();
      clearHistory();
      clearToken();
      setImageDropped(false);
      setMainMenuOpen(false);
      setOpenAssetViewerModal(true);
    }
  };

  const previewRef = useRef<PreviewRef>(null);
  const { displayNotification } = useNotification();
  const upsertToken = useUpdateRealmTokenFormData({
    onSuccess: (data) => {
      updateToken(() => ({
        id: data.id,
        name: data.name,
        isVisibleInTool: data.isVisibleInTool,
        isPublic: data.isPublic,
        edited: false,
      }));
      displayNotification({ message: `${data.name} saved!`, type: 'success' });
    },
    onError: () => {
      displayNotification({ message: 'Error saving Token', type: 'error' });
    },
  });

  const [isFavorite, setIsFavorite] = React.useState(false);
  const favoriteToken = useFavoriteRealmToken({
    onSuccess: (data) => {
      updateToken((prevData) => ({
        edited: prevData.data?.edited,
        isFavorite: data,
      }));
      displayNotification({ message: 'Marked favorite', type: 'success' });
    },
    onError: () => {
      displayNotification({ message: 'Error favoriting Token', type: 'error' });
    },
  });

  const unfavoriteToken = useUnFavoriteRealmToken({
    onSuccess: (data) => {
      updateToken((prevData) => ({
        edited: prevData.data?.edited,
        isFavorite: data,
      }));
      displayNotification({ message: 'Marked unfavorite', type: 'success' });
    },
    onError: () => {
      displayNotification({ message: 'Error favoriting Token', type: 'error' });
    },
  });

  const handleFavorite = (value: boolean) => {
    if (value) {
      favoriteToken.mutate({
        // @ts-ignore
        body: value,
        pathParams: {
          realmId: token.data?.realmId ?? 'error',
          tokenId: token.data?.id ?? 'error',
        },
      });
    } else {
      unfavoriteToken.mutate({
        // @ts-ignore
        body: value,
        pathParams: {
          realmId: token.data?.realmId ?? 'error',
          tokenId: token.data?.id ?? 'error',
        },
      });
    }
  };

  const handleSaveAs = () => {
    setMainMenuOpen(false);
    if (previewRef.current) {
      if (!auth?.user) {
        setOpenLoginModal(true);
      } else {
        previewRef.current.savePreview();
      }
    }
  };

  const handleSave = useCallback(() => {
    setMainMenuOpen(false);
    if (previewRef.current && token.data?.edited) {
      if (token?.data?.id) {
        const image = document.createElement('img');
        const { stage } = previewRef.current;
        stage.toBlob({
          x: 0,
          y: 0,
          width: 260,
          height: 260,
          pixelRatio: 1,
          callback: (blob) => {
            image.src = stage.toDataURL({
              x: 0,
              y: 0,
              width: 260,
              height: 260,
              pixelRatio: 1,
            });

            const updatedToken = {
              finalImageBlob: blob,
              finalImageSrc: image.src,
              edits: stageData,
            };
            updateToken((prevData) => ({
              ...updatedToken,
              edited: prevData.data?.edited,
            }));

            const data = {
              TokenName: token?.data?.name ?? '',
              TokenDescription: token?.data?.description ?? '',
              Token: updatedToken.finalImageBlob ?? new Blob(),
              OriginalImage:
                (token?.data?.droppedImageBlob as Blob) ?? new Blob(),
              TokenEdits: updatedToken.edits ?? null,
              IsPublic: token?.data?.isPublic,
              IsVisibleInTool: token?.data?.isVisibleInTool,
              Notes: [token?.data?.notes as Note],
            };

            const formdata = serialize(data, { indices: true });
            upsertToken.mutate({
              // @ts-ignore
              body: formdata,
              pathParams: {
                realmId: token?.data.realmId ?? 'error',
                tokenId: token?.data?.id ?? 'error',
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
              },
            });
          },
        });
      } else {
        handleSaveAs();
      }
    }
  }, [previewRef, token]);

  const handleStageClear = () => {
    clearItems();
    setImageDropped(false);
    setMainMenuOpen(false);
  };

  useHotkeys(
    'ctrl+s',
    (e) => {
      e.preventDefault();
      handleSave();
    },
    {},
    [previewRef, token],
  );

  useHotkeys(
    'ctrl+z',
    (e) => {
      e.preventDefault();
      goToPast();
    },
    {},
    [goToPast],
  );

  useHotkeys(
    'ctrl+y',
    (e) => {
      e.preventDefault();
      goToFuture();
    },
    {},
    [goToFuture],
  );

  useHotkeys(
    'backspace',
    (e) => {
      e.preventDefault();
      setImageDropped(false);
      deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
    },
    { enabled: Boolean(selectedItems.length) },
    [selectedItems, transformer.transformerRef.current],
  );

  useHotkeys(
    'delete',
    (e) => {
      e.preventDefault();
      setImageDropped(false);
      deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
    },
    { enabled: Boolean(selectedItems.length) },
    [selectedItems, transformer.transformerRef.current],
  );

  useEffect(() => {
    if (stageRef.current) {
      setContainer(stageRef.current!.container());
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar elevation={0} position="fixed" open={open}>
          <Toolbar variant="dense">
            <Grid container alignItems="stretch">
              <Grid item xs={4}>
                <Box height="100%" display="flex" alignItems="center">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    ref={mainMenuRef}
                    sx={{
                      marginRight: 5,
                      ...(open && { display: 'none' }),
                    }}
                    onClick={handleToggle}
                  >
                    <FontAwesomeIcon icon={solid('bars')} />
                  </IconButton>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                  >
                    <Button color="inherit" onClick={() => goToPast()}>
                      <FontAwesomeIcon icon={solid('arrow-left')} />
                    </Button>
                    <Button color="inherit" onClick={() => goToFuture()}>
                      <FontAwesomeIcon icon={solid('arrow-right')} />
                    </Button>
                  </ButtonGroup>

                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                    size="small"
                  >
                    <Button
                      color="inherit"
                      onClick={handleHistoryToggle}
                      sx={{ ml: 2, height: '26px' }}
                      ref={historyMenuRef}
                    >
                      <FontAwesomeIcon
                        fontSize={14}
                        icon={solid('rotate-left')}
                      />
                      <Typography fontSize={14} ml={1}>
                        History
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Box>

                <Popper
                  open={historyMenuOpen}
                  anchorEl={historyMenuRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start'
                            ? 'left top'
                            : 'left bottom',
                      }}
                    >
                      <Box sx={{ backgroundColor: '#585858', mt: '10px' }}>
                        <ClickAwayListener onClickAway={handleHistoryMenuClose}>
                          <MenuList
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            sx={{ maxHeight: 300, overflow: 'auto' }}
                          >
                            {past.length && current ? (
                              [...past, current].map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem
                                  onClick={handleHistoryMenuClose}
                                  key={index}
                                >
                                  {item?.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>No Changes</MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Grow>
                  )}
                </Popper>
                <Popper
                  open={mainMenuOpen}
                  anchorEl={mainMenuRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start'
                            ? 'left top'
                            : 'left bottom',
                      }}
                    >
                      <Box sx={{ backgroundColor: '#585858', mt: '3px' }}>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuList
                            id="composition-menu"
                            aria-labelledby="composition-button"
                          >
                            {auth?.user && (
                              <MenuItem onClick={handleOpenAssetViewer}>
                                Files
                              </MenuItem>
                            )}
                            {imageDropped && (
                              <>
                                <MenuItem
                                  onClick={handleSave}
                                  disabled={!token.data?.edited}
                                >
                                  Save
                                </MenuItem>
                                <MenuItem onClick={handleSaveAs}>
                                  Save As
                                </MenuItem>
                                <MenuItem onClick={handleStageClear}>
                                  Clear Canvas
                                </MenuItem>
                              </>
                            )}
                            {auth?.user && imageDropped && (
                              <MenuItem
                                onClick={() =>
                                  handleExitSession({ wait: false })
                                }
                              >
                                Exit Session
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Grow>
                  )}
                </Popper>
                <Popper
                  open={accountMenuOpen}
                  anchorEl={accountMenuRef.current}
                  role={undefined}
                  placement="bottom-end"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-end'
                            ? 'right top'
                            : 'left bottom',
                      }}
                    >
                      <Box sx={{ backgroundColor: '#585858', mt: '3px' }}>
                        <ClickAwayListener onClickAway={handleAccountMenuClose}>
                          <MenuList
                            id="composition-menu"
                            aria-labelledby="composition-button"
                          >
                            {auth?.user && (
                              <MenuItem onClick={handleOpenAccountViewer}>
                                View Profile
                              </MenuItem>
                            )}
                            {process.env.REACT_APP_API_URL?.includes('dev') ? (
                              <MenuItem onClick={handleCopyAccessToken}>
                                Copy Access Token
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item xs={4}>
                <Box height="100%" display="flex" alignItems="center">
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, textAlign: 'center' }}
                  >
                    {token?.data?.name ?? 'Realm Worlds'}
                    {token?.data?.edited ? '*' : ''}
                    {token?.data?.id && (
                      <Checkbox
                        disabled={
                          favoriteToken.isLoading || unfavoriteToken.isLoading
                        }
                        checked={isFavorite}
                        onChange={(e) => {
                          setIsFavorite(e.target.checked);
                          handleFavorite(e.target.checked);
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={regular('star')}
                            size="sm"
                            color="#ffffff"
                          />
                        }
                        checkedIcon={
                          <FontAwesomeIcon
                            icon={solid('star')}
                            size="sm"
                            color="#ffffff"
                          />
                        }
                      />
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                {auth?.user == null ? (
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      sx={{ backgroundColor: '#FF6746' }}
                      variant="contained"
                      onClick={handleMenu}
                      aria-controls={menuOpen ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      Sign in
                    </Button>
                  </Box>
                ) : (
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button variant="contained" onClick={handleLogout}>
                      Logout
                    </Button>
                    <IconButton
                      size="small"
                      color="secondary"
                      ref={accountMenuRef}
                      sx={{ ml: 2 }}
                      onClick={() => setAccountMenuOpen((prev) => !prev)}
                    >
                      <FontAwesomeIcon
                        icon={solid('ellipsis')}
                        size="lg"
                        color="#ffffff"
                      />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Panel onStageChange={handleNewChange} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {!imageDropped && (
            <Dropzone onDrop={handleImageDrop} noClick>
              {({ getRootProps, getInputProps, open: openfs }) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  {...getRootProps()}
                  sx={{
                    backgroundColor: !imageDropped
                      ? 'rgba(0,0,0,.5)'
                      : 'transparent',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: !imageDropped ? 100 : 1,
                  }}
                >
                  <Grid item xs={12}>
                    <input {...getInputProps()} />
                    {!imageDropped && (
                      <>
                        <Typography
                          align="center"
                          fontSize={50}
                          color="rgba(255, 255, 255, 0.3)"
                        >
                          DRAG and DROP IMAGE
                        </Typography>
                        <Typography
                          align="center"
                          fontSize={30}
                          sx={{
                            textDecoration: 'underline',
                            color: 'rgba(255, 255, 255, 0.3)',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            openfs();
                          }}
                        >
                          Upload
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Dropzone>
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <ReactReduxContext.Consumer>
                {({ store }) => (
                  <Provider store={store}>
                    <Box position="absolute" right={24} zIndex={100}>
                      <Box mt={7} maxWidth={260}>
                        {imageDropped && (
                          <Preview
                            ref={previewRef}
                            onSave={() => {
                              handleExitSession({ wait: true });
                            }}
                          />
                        )}
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="start"
                          spacing={1}
                        >
                          <Box
                            sx={{
                              mt: 4,
                              p: '4px',
                              borderRadius: '10px 0px 10px 10px',
                              backgroundColor: '#FF6746',
                              width: 150,
                              height: 60,
                            }}
                          >
                            <Typography
                              m={0}
                              p={0}
                              textAlign="center"
                              fontSize={12}
                            >
                              IT&apos;S DANGEROUS TO GO ALONE!
                            </Typography>
                            <Link
                              color="#E923B1"
                              target="_blank"
                              href="https://chrome.google.com/webstore/category/extensions?hl=en"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize={12}
                                mt={2}
                                textAlign="center"
                                m={0}
                                p={0}
                              >
                                Get the Extension
                              </Typography>
                            </Link>
                          </Box>
                          <Typography fontSize={50}>🧙</Typography>
                        </Stack>
                      </Box>
                    </Box>
                    <Stage
                      ref={stageRef}
                      width={canvasWidth}
                      height={canvasHeight}
                      onMouseDown={onMouseDownOnStage}
                    >
                      <Layer listening={false} draggable={false} />
                      <Layer>
                        {stageData.length
                          ? sortedStageData.map((item) => renderObject(item))
                          : null}
                        <Transformer
                          ref={transformer.transformerRef}
                          keepRatio
                          shouldOverdrawWholeArea
                          boundBoxFunc={(_, newBox) => newBox}
                          onTransformEnd={transformer.onTransformEnd}
                        />
                      </Layer>
                    </Stage>
                    <EllipsisMenu />
                    {container ? (
                      <Drop
                        callback={handleCanvasImageDrop}
                        targetDOMElement={container}
                      />
                    ) : null}
                    <Notification />
                  </Provider>
                )}
              </ReactReduxContext.Consumer>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* {!userConfigIsLoading ? (
        <WelcomeModal
          open={
            openWelcomeModal && !(userConfig?.autoDismissIntroScreen === true)
          }
          onClose={() => {}}
          onConfirm={() => {}}
          setOpen={setOpenWelcomeModal}
        />
      ) : null} */}
      <LoginModal
        open={openLoginModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenLoginModal}
      />
      {auth?.user !== null ? (
        <>
          <AssetViewerModal
            open={openAssetViewerModal}
            onClose={() => {}}
            onConfirm={() => {}}
            setOpen={setOpenAssetViewerModal}
          />
          <AccountModal
            open={openAccountViewer}
            onClose={() => {}}
            onConfirm={() => {}}
            setOpen={setOpenAccountViewer}
          />
        </>
      ) : null}
      {(upsertToken.isLoading || loading) && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (currentTheme) => currentTheme.zIndex.drawer + 1,
          }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
